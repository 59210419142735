import {
  Grid,
  Stack,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import bomberman_img from "../assets/bomberman.webp";
import seace_img from "../assets/seace.jpg";
import map_img from "../assets/map_project.png";
import { handleButtonClickSendMetric } from "./HandleButtonClickSendMetric";

function ProjectsSection() {
  const Projects = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction="column" spacing={5}>
        <Stack>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
            }}
          >
            Proyectos
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={10}>
          <Card sx={{ maxWidth: 345, padding: 2 }}>
            <CardActionArea component={Link} to="/projects/bomberman_project"
            onClick={() => handleButtonClickSendMetric("Proyecto_Bomberman", "fratellidevs_com")}
            >
              <CardMedia
                component="img"
                height="200"
                image={bomberman_img}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  Proyecto Juego de Bomberman Multijugador Web
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Este proyecto trata sobre la creación de la replica del juego
                  de Bomberman, a diferencia este juego es web y multijugador en
                  tiempo real.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 345, padding: 2 }}>
            <CardActionArea component={Link} to="/projects/seace_project"
            onClick={() => handleButtonClickSendMetric("Proyecto_Seace", "fratellidevs_com")}
            >
              <CardMedia
                component="img"
                height="200"
                image={seace_img}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  Proyecto Notificador de Convocatorias del Estado Peruano SEACE
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Este proyecto trata sobre la realización de un sistema que
                  notifica a sus clientes sobre las convocatorias de trabajo más
                  recientes, publicadas por la plataforma SEACE.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card sx={{ maxWidth: 345, padding: 2 }}>
            <CardActionArea component={Link} to="/projects/map_project"
            onClick={() => handleButtonClickSendMetric("Proyecto_Rutas", "fratellidevs_com")}
            >
              <CardMedia
                component="img"
                height="200"
                image={map_img}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  Proyecto de rutas de las lineas de transporte urbano de la ciudad de Juliaca
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Este proyecto trata sobre la creación de un sistema que contenga las rutas de
                  las lineas que brindan transporte urbano en toda la ciudad de Juliaca, y una interacción con ellas.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
      }}
      container
    >
      {Projects}
    </Grid>
  );
}

export default ProjectsSection;
