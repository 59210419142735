import { Grid, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import bomberman_project_process from "../assets/bomberman_project_process.png";

function DescriptionSectionProjects() {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isMediumOrLargeScreen = useMediaQuery("(min-width:601px)");
  const About = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction={{ xs: "column", sm: "row" }} spacing={0}>
        <Grid item xs={12} sm={6} container>
          <img
            src={bomberman_project_process}
            alt="Logo"
            style={{
              width: isMediumOrLargeScreen ? "90%" : "100%",
              maxHeight: isMediumOrLargeScreen ? "90%" : "100%",
              margin: "auto",
             
            }}
          />
          {/* <Typography variant="body1">Logo en negro</Typography> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Descripción General
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "center" : "justify",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            Bomberman Multiplayer Web es una aplicación interactiva de juego
            multijugador en línea desarrollada utilizando tecnologías modernas
            como Node.js, TypeScript, WebSockets y Phaser 3. El objetivo
            principal de esta aplicación es permitir la creación de sesiones de
            juego en las que varios jugadores pueden unirse mediante un código
            de enlace único. Una vez que los jugadores se han unido a una
            sesión, el jugador creador tiene la capacidad de iniciar el juego.
            Hasta ese momento, todos los jugadores permanecen en estado
            detenido.
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Características del Juego
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Sesiones de Juego:</strong> Los jugadores pueden crear y
                unirse a sesiones de juego mediante un código de enlace.
              </li>
              <li>
                <strong>Inicio del Juego:</strong> El jugador que crea la sesión
                tiene la capacidad exclusiva de iniciar el juego.
              </li>
              <li>
                <strong>Estado Inicial:</strong> Todos los jugadores están en un
                estado detenido hasta que se inicia el juego.
              </li>
              <li>
                <strong>Vidas de los Jugadores:</strong> Cada jugador cuenta con
                4 vidas.
              </li>
              <li>
                <strong>Mecánica de Juego:</strong> Los jugadores pueden colocar
                bombas que, al explotar, pueden eliminar a otros jugadores o a
                sí mismos.
              </li>
              <li>
                <strong>Generación de Mapas:</strong> Los mapas del juego se
                generan de manera procedimental, garantizando una experiencia
                única en cada partida.
              </li>
              <li>
                <strong>Diseño Gráfico:</strong> Todos los gráficos del juego,
                excepto el personaje del jugador (Bomberman), fueron diseñados
                original y exclusivamente para esta aplicación.
              </li>
            </ul>
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Arquitectura del Sistema
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Servidor de Unión:</strong>Un servidor dedicado donde
                los jugadores se unen a las sesiones de juego.
              </li>
              <li>
                <strong>Servidor de WebSockets:</strong>Un servidor separado
                encargado de manejar la comunicación en tiempo real durante el
                juego.
              </li>
            </ul>
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Despliegue y CI/CD
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Integración Continua:</strong>Implementada mediante
                GitHub Actions, facilitando el despliegue automatizado de la
                aplicación con cada cambio en la rama principal del proyecto.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
        paddingTop: "5vh",
      }}
      container
    >
      {About}
    </Grid>
  );
}

export default DescriptionSectionProjects;
