import React from "react";
import Carousel from "react-material-ui-carousel";
import img1 from "../assets/LandImage1.jpg";
import img2 from "../assets/LandImage2.jpg";
import img3 from "../assets/LandImage3.jpg";
import { Paper } from "@mui/material";

function MenuCarousel(props: any) {
  var items = [
    {
      image: img1,
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
    },
    {
      image: img2,
      name: "Random Name #2",
      description: "Probably the most thing you have ever seen!",
    },
    {
      image: img3,
      name: "Random Name #3",
      description: "Probably the most random thing you haeen!",
    },
  ];

  return (
    <Carousel
      sx={{
        position: "absolute",
        height: { xs: "70vh", sm: "75vh", md: "80vh" },
        width: "100vw",
        zIndex: -1,
        top: 0,
        right: 0,
      }}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props: any) {
  return (
    <Paper>
      <img
        src={props.item.image}
        alt={props.item.name}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          objectFit: "cover", // Asegura que la imagen cubra el área sin distorsionarse
          zIndex: -1, // Asegura que la imagen esté detrás del contenido
        }}
      />
    </Paper>
  );
}

export default MenuCarousel;
