import { Button, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MenuCarousel from "../components/MenuCarousel";
import { handleButtonClickSendMetric } from "./HandleButtonClickSendMetric";

function LandSection() {
  const MiddleContent = (
    <Grid
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
      }}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <MenuCarousel />

      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center", // Asegura que el texto esté centrado
          pl: {xs: "5vw", sm: "10vw", md:"20vw", lg:"25vw"},
          pr: {xs: "5vw", sm: "10vw", md:"20vw", lg:"25vw"},
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: {
              xs: "2rem", // Pequeño (extra small)
              sm: "2.5rem", // Mediano (small)
              md: "3rem", // Mediano (medium)
              lg: "3.5rem", // Grande (large)
              xl: "4rem", // Extra grande (extra large)
            },
          }}
        >
          “Transformamos ideas en soluciones digitales innovadoras”
        </Typography>
        <br></br>
        <Link to="/contact"style={{ textDecoration: "none", padding:"20px"}}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            fontSize: {
              xs: "1rem", // Pequeño (extra small)
              sm: "1.1rem", // Mediano (small)
              md: "1.3rem", // Mediano (medium)
            },
            padding: {
              xs: "8px 16px", // Pequeño (extra small)
              sm: "10px 20px", // Mediano (small)
              md: "12px 24px", // Mediano (medium)
            },
            "&:hover": {
              backgroundColor: "grey", // Color de fondo cuando se hace hover
            },
          }}
          onClick={() => handleButtonClickSendMetric("Button_Comunicarse", "fratellidevs_com")}
        >
          Comunicarse
        </Button>
        </Link>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        minHeight: "55vh",
        maxWidth: "100vw",
      }}
    >
      {MiddleContent}
    </Grid>
  );
}

export default LandSection;
