import { Grid, Stack, Typography, Box, Button } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import map from "../assets/map_project.png";
import logo from "../assets/logo_white.png";
import { Link } from "react-router-dom";
import { handleButtonClickSendMetric } from "../../../HandleButtonClickSendMetric";

function LandSectionProjects() {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isMediumOrLargeScreen = useMediaQuery("(min-width:601px)");
  const MiddleContent = (
    <Grid
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
      }}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          minWidth: "100vw",
          minHeight: "45vh",
          borderRadius: 2,
          bgcolor: "rgb(51,153,255)",
          top: 0,
          right: 0,
          position: "absolute",
          zIndex: -1,
        }}
      />

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={0}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={10} sm={6} md={5}>
          <Typography
            sx={{
              fontSize: {
                xs: "2rem", // Pequeño (extra small)
                sm: "2rem", // Mediano (small)
                md: "2.5rem", // Mediano (medium)
                lg: "2.5rem", // Grande (large)
                xl: "3.3rem", // Extra grande (extra large)
              },
              fontWeight: "bold",
              textAlign: "center",
              paddingLeft: isSmallScreen ? "0" : "25%",
            }}
          >
            Proyecto de rutas de las lineas de transporte urbano de la ciudad de
            Juliaca
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5} container>
          <img
            src={map}
            alt="Logo"
            style={{
              maxWidth: "80%",
              height: isMediumOrLargeScreen ? "80%" : "60%",
              marginTop: "10%",
              marginBottom: "10%",
              paddingRight: isSmallScreen ? "0" : "10%",
              paddingLeft: isSmallScreen ? "0" : "10%",
            }}
          />
        </Grid>
      </Stack>
      <Stack
        direction={{ sm: "column", md: "row" }}
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          component={Link}
          to="/ruta_urbana"
          variant="contained"
          sx={{
            backgroundColor: "rgb(0,76,153)",
            fontSize: {
              xs: "1rem",
              sm: "1.1rem",
              md: "1.3rem",
            },
            padding: {
              xs: "8px 16px",
              sm: "10px 20px",
              md: "12px 24px",
            },
            "&:hover": {
              backgroundColor: "rgb(0,102,204)",
            },
          }}
          onClick={() => handleButtonClickSendMetric("button_ruta_urbana", "projects/map_project")}
        >
          <img src={logo} height="45px" width="45px" alt="logo github" />
          Ruta Urbana
        </Button>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        minHeight: "40vh",
        maxWidth: "100vw",
      }}
    >
      {MiddleContent}
    </Grid>
  );
}

export default LandSectionProjects;
