import {
  Button,
  Grid,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import useResponsive from "../hooks/useResponsive";
import logo_name from "../assets/logo_name.png";
import { Link, ScrollRestoration } from "react-router-dom";
import { handleButtonClickSendMetric } from "./HandleButtonClickSendMetric";

function HeaderSection() {
  const isDesktop = useResponsive("up", "md");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        minHeight: "10vh",

        p: {
          xs: 1, // Padding de 1 en pantallas pequeñas (extra small)
          sm: 2, // Padding de 2 en pantallas pequeñas (small)
          md: 4, // Padding de 4 en pantallas medianas (medium)
          lg: 5, // Padding de 6 en pantallas grandes (large)
          xl: 5, // Padding de 6 en pantallas extra grandes (extra large)
        },
        backgroundColor: "rgba(255, 255, 255, 0.3)", // Fondo semi-transparente
        backdropFilter: "blur(4px)", // Aplicar desenfoque
      }}
    >
      <ScrollRestoration />
      <Grid item md={6} xs={6} container>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <Link to="/">
            <img
              src={logo_name}
              style={{
                maxWidth: "250px",
                maxHeight: "80px",
              }}
              alt="logo and name"
            />
          </Link>
        </Stack>
      </Grid>
      {isDesktop && (
        <Grid item md={6} xs={6} container justifyContent="flex-end">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button component={Link} to="/" sx={{ color: "black" }}
            onClick={() => handleButtonClickSendMetric("PP_Inicio", "fratellidevs_com")}
            >
              Inicio
            </Button>
            <Button component={Link} to="/about" sx={{ color: "black" }}
            onClick={() => handleButtonClickSendMetric("PP_Acerca_De", "fratellidevs_com")}
            >
              Acerca de
            </Button>
            <Button component={Link} to="/projects" sx={{ color: "black" }}
            onClick={() => handleButtonClickSendMetric("PP_Proyectos", "fratellidevs_com")}
            >
              Proyectos
            </Button>
            <Button component={Link} to="/contact" sx={{ color: "black" }}
            onClick={() => handleButtonClickSendMetric("PP_Contacto", "fratellidevs_com")}
            >
              Contacto
            </Button>
          </Stack>
        </Grid>
      )}
      {!isDesktop && (
        <Grid item md={6} xs={6} container justifyContent="flex-end">
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              disableScrollLock={true}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "black", // Fondo del menú en negro
                },
              }}
            >
              <MenuItem
                component={Link}
                to="/"
                onClick={handleClose}
                sx={{ color: "white" }}
              >
                Inicio
              </MenuItem>
              <MenuItem
                component={Link}
                to="/about"
                onClick={handleClose}
                sx={{ color: "white" }}
              >
                Acerca de
              </MenuItem>
              <MenuItem
                component={Link}
                to="/projects"
                onClick={handleClose}
                sx={{ color: "white" }}
              >
                Proyectos
              </MenuItem>
              <MenuItem
                component={Link}
                to="/contact"
                onClick={handleClose}
                sx={{ color: "white" }}
              >
                Contacto
              </MenuItem>
            </Menu>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}

export default HeaderSection;
