import { Box } from "@mui/material";

import HeaderSection from "../components/HeaderSection";
import LandSection from "../components/LandSection";
import ServicesSection from "../components/ServicesSection";
import ProjectsSection from "../components/ProjectsSection";
import AboutSection from "../components/AboutSection";
import TecnologySection from "../components/TecnologySection";
import FooterSection from "../components/FooterSection";

const Home = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
      }}
    >
      <HeaderSection />
      <LandSection />
      <AboutSection />
      <ServicesSection />
      <TecnologySection />
      <ProjectsSection />
      <FooterSection />
    </Box>
  );
};

export default Home;
