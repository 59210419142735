import { Grid, Stack, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import data_img from "../assets/data.png";
import software_img from "../assets/software.png";
import web_mobile_img from "../assets/web-mobile.png";
import web_img from "../assets/web.png";

function ServicesSection() {
  const DemoPaper = styled(Paper)(({ theme }) => ({
    width: 120,
    height: 120,
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3),
  }));
  const Services = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction="column" spacing={10}>
        <Stack>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
            }}
          >
            Servicios
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={4}
          alignItems="center"
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={4}
            alignItems="center"
          >
            <DemoPaper elevation={1} variant="elevation">
              <img
                src={web_img}
                style={{
                  maxWidth: "50px",
                  maxHeight: "50px",
                }}
                alt="Web"
              />
              Última Tecnología
            </DemoPaper>
            <DemoPaper elevation={1} variant="elevation">
              <img
                src={web_mobile_img}
                style={{
                  maxWidth: "50px",
                  maxHeight: "50px",
                }}
                alt="Web Mobile"
              />
              Proyectos Web y Mobile
            </DemoPaper>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={4}
            alignItems="center"
          >
            <DemoPaper elevation={1} variant="elevation">
              <img
                src={software_img}
                style={{
                  maxWidth: "50px",
                  maxHeight: "50px",
                }}
                alt="Software"
              />
              Desarrollo Software
            </DemoPaper>
            <DemoPaper elevation={1} variant="elevation">
              <img
                src={data_img}
                style={{
                  maxWidth: "50px",
                  maxHeight: "50px",
                }}
                alt="Data"
              />
              Inteligencia Artificial
            </DemoPaper>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
      }}
      container
    >
      {Services}
    </Grid>
  );
}

export default ServicesSection;
