import { Grid, Typography, Box } from "@mui/material";
import banner from "../../../assets/banner.png";

function LandSectionAbout() {
  
  const MiddleContent = (
    <Grid
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
        position: "relative",
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
      <Box
          sx={{
            width: '100%',
            minHeight: '30vh',  // Asegura una altura mínima
            backgroundImage: `url(${banner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            // Para asegurar que el tamaño del contenedor sea dinámico:
            '@media (min-width: 400px)': {
              minHeight: '5vh',
            },
            '@media (min-width: 600px)': {
              minHeight: '10vh',
            },
            '@media (min-width: 960px)': {
              minHeight: '15vh',
            },
            '@media (min-width: 1200px)': {
              minHeight: '20vh',
            },
            '@media (min-width: 1600px)': {
              minHeight: '25vh',
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "2rem", // Pequeño (extra small)
                sm: "3rem", // Mediano (small)
                md: "3rem", // Mediano (medium)
                lg: "4rem", // Grande (large)
                xl: "5rem", // Extra grande (extra large)
              },
              fontWeight: "bold",
              textAlign: "center",
              paddingLeft: "10%",
              paddingRight: "10%",
              color: 'black', // Asegurarse de que el texto sea visible sobre la imagen
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)', // Agregar sombra al texto para mayor legibilidad
            }}
          >
            Acerca de Nosotros
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
      }}
    >
      {MiddleContent}
    </Grid>
  );
}

export default LandSectionAbout;
