import { Box } from "@mui/material";
import HeaderSection from "../components/HeaderSection";
import FooterSection from "../components/FooterSection";
import EmailForm from "../components/EmailForm";

const Contact = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
      }}
    >
      <HeaderSection />
      <EmailForm />
      <FooterSection />
    </Box>
  );
};

export default Contact;
