import { Grid, Typography } from "@mui/material";

function LandSectionPrivacyPolicy() {
  const MiddleContent = (
    <Grid
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
        position: "relative",
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          sx={{
            fontSize: {
              xs: "2rem",
              sm: "2rem",
              md: "3rem",
            },
            fontWeight: "bold",
            textAlign: "center",
            paddingBottom: "10px",
            color: "black",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
          }}
        >
          Politicas de Privacidad
        </Typography>
      </Grid>
      <Grid item xs={10} sm={8} md={6} lg={5} justifyContent="center">
        <Typography variant="body1" align="left" paragraph>
          En Fratelli Devs, respetamos y protegemos su privacidad. Esta Política
          de Privacidad describe cómo recopilamos, usamos y compartimos su
          información personal cuando visita y utiliza nuestro sitio web. Al
          utilizar nuestro sitio web, usted acepta las prácticas descritas en
          esta política.
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          1. Información que Recopilamos
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Recopilamos varios tipos de información para proporcionar y mejorar
          nuestros servicios:
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>Información Personal:</strong> Puede incluir su nombre,
          dirección de correo electrónico, número de teléfono y cualquier otra
          información que nos proporcione al ponerse en contacto con nosotros o
          al registrarse en nuestro sitio web.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>Información de Uso:</strong> Recopilamos información sobre
          cómo utiliza nuestro sitio web, incluyendo su dirección IP, tipo de
          navegador, páginas visitadas, tiempo de visita y otras actividades
          similares.
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          2. Uso de la Información
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Utilizamos la información que recopilamos para los siguientes fines:
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Proporcionar y mantener nuestros servicios.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Mejorar y personalizar su experiencia en nuestro sitio web.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Comunicarnos con usted, responder a sus preguntas y atender sus
          solicitudes.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Enviar actualizaciones, noticias y promociones relacionadas con
          nuestros servicios.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Analizar el uso del sitio web para mejorar nuestras ofertas.
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          3. Compartir Información
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          No vendemos, alquilamos ni compartimos su información personal con
          terceros, excepto en las siguientes circunstancias:
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>Con Proveedores de Servicios:</strong> Podemos compartir su
          información con proveedores de servicios que nos ayudan a operar y
          mejorar nuestro sitio web y servicios.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>Para Cumplir con la Ley:</strong> Podemos divulgar su
          información si así lo requiere la ley o en respuesta a solicitudes
          legales de autoridades públicas.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>Para Proteger Nuestros Derechos:</strong> Podemos compartir su
          información para proteger los derechos, propiedad o seguridad de
          Fratelli Devs, nuestros usuarios u otros.
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          4. Seguridad de la Información
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Nos comprometemos a proteger la seguridad de su información personal.
          Utilizamos medidas técnicas y organizativas adecuadas para proteger su
          información contra el acceso no autorizado, pérdida, destrucción o
          alteración.
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          5. Sus Derechos
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Usted tiene derecho a:
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Acceder a su información personal que poseemos.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Solicitar la corrección de cualquier información personal inexacta.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Solicitar la eliminación de su información personal en ciertos
          casos.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          - Oponerse al procesamiento de su información personal en ciertas
          circunstancias.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Para ejercer estos derechos, contáctenos en fratelli.devs@gmail.com o
          llamando al +51929747272.
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          6. Enlaces a Otros Sitios Web
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Nuestro sitio web puede contener enlaces a otros sitios web que no son
          operados por nosotros. No tenemos control sobre el contenido y las
          prácticas de privacidad de esos sitios y no asumimos ninguna
          responsabilidad por ellos. Le recomendamos que revise las políticas de
          privacidad de cualquier sitio web de terceros que visite.
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          7. Cambios a Esta Política de Privacidad
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Nos reservamos el derecho de actualizar esta Política de Privacidad en
          cualquier momento. Le notificaremos sobre cualquier cambio publicando
          la nueva política en nuestro sitio web. Le recomendamos revisar esta
          política periódicamente para estar al tanto de cualquier cambio.
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          8. Contáctenos
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Si tiene alguna pregunta o inquietud sobre esta Política de
          Privacidad, por favor contáctenos en:
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          Correo electrónico: fratelli.devs@gmail.com
          <br />
          Teléfono: +51929747272
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
      }}
    >
      {MiddleContent}
    </Grid>
  );
}

export default LandSectionPrivacyPolicy;
