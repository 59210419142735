import { Grid, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import seace_project_process from "../assets/seace_project_process.png";

function DescriptionSectionProjects() {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isMediumOrLargeScreen = useMediaQuery("(min-width:601px)");
  const About = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction={{ xs: "column", sm: "row" }} spacing={0}>
        <Grid item xs={12} sm={6} container>
          <img
            src={seace_project_process}
            alt="Logo"
            style={{
              width: isMediumOrLargeScreen ? "90%" : "100%",
              maxHeight: isMediumOrLargeScreen ? "90%" : "100%",
              margin: "auto",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Descripción General
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "center" : "justify",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            El Proyecto Notificador de Convocatorias del Estado Peruano SEACE es
            una solución automatizada diseñada para recopilar y notificar las
            últimas convocatorias de trabajo publicadas en la página del Sistema
            Electrónico de Contrataciones del Estado (SEACE) de Perú. La
            aplicación está dividida en dos servicios independientes: Seace
            Scrapy y Whatsapp Sender. Ambos servicios operan de manera autónoma
            pero se complementan para proporcionar una funcionalidad completa de
            notificación.
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Seace Scrapy:
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Funcionalidad: </strong> Automatiza la extracción de
                convocatorias de la página SEACE.
              </li>
              <li>
                <strong>Proceso:</strong>
                <ul>
                  <li>Ingreso a la página SEACE utilizando Selenium.</li>
                  <li>Resolución de captchas mediante OCR.</li>
                  <li>
                    Búsqueda y descarga de las últimas convocatorias en formato
                    Excel.
                  </li>
                  <li>
                    Parseo del archivo Excel para extraer datos relevantes.
                  </li>
                  <li>
                    Inserción de los datos en una base de datos PostgreSQL.
                  </li>
                  <li>
                    Repetición del proceso cada 5 minutos utilizando cron,
                    asegurando la inserción de convocatorias no repetidas.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Whatsapp Sender:
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Funcionalidad: </strong> Envía notificaciones de
                convocatorias a los clientes a través de WhatsApp.
              </li>
              <li>
                <strong>Proceso:</strong>
                <ul>
                  <li>
                    Inicio de sesión en WhatsApp utilizando Selenium y la API de
                    imgbb para gestionar el código QR de inicio.
                  </li>
                  <li>
                    Recopilación de información de clientes y convocatorias
                    desde la base de datos PostgreSQL.
                  </li>
                  <li>
                    Filtrado de convocatorias según las palabras clave definidas
                    por cada cliente.
                  </li>
                  <li>
                    Envío de mensajes de WhatsApp a los clientes con las
                    convocatorias relevantes.
                  </li>
                  <li>
                    Ejecución continua del servicio, verificando nuevas
                    convocatorias cada 2 minutos.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Arquitectura del Sistema
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Repositorios Independientes:</strong>Cada servicio
                cuenta con su propio repositorio en GitHub.
              </li>
              <li>
                <strong>Despliegue sin Interfaz Gráfica:</strong>Ambos servicios
                están diseñados para operar en servidores sin interfaz gráfica,
                utilizando únicamente la consola y Docker.
              </li>
            </ul>
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Despliegue y CI/CD
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Docker:</strong>Facilita el despliegue y la
                administración de los servicios en diferentes entornos.
              </li>
              <li>
                <strong>GitHub:</strong>Almacena y gestiona el código fuente de
                ambos servicios.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
        paddingTop: "5vh",
      }}
      container
    >
      {About}
    </Grid>
  );
}

export default DescriptionSectionProjects;
