import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Stack } from "@mui/material";

const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5";
const WEATHER_API_KEY = "58b05f0d25fed702b66b33ffdba40dd9";
const lat = "-15.500216360387599";
const lon = "-70.12856705155708";

interface WeatherData {
  name: string;
  weather: {
    description: string;
    icon: string;
  }[];
  main: {
    temp: number;
    humidity: number;
    pressure: number;
  };
  wind: {
    speed: number;
  };
}

const CurrentWeather: React.FC = () => {
  const [currentWeather, setCurrentWeather] = useState<WeatherData | null>(null);

  useEffect(() => {
    const fetchCurrentWeather = async () => {
      try {
        const response = await fetch(
          `${WEATHER_API_URL}/weather?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}`
        );
        const weatherData: WeatherData = await response.json();
        setCurrentWeather(weatherData);
      } catch (err) {
        console.error("Error fetching the weather data", err);
      }
    };

    fetchCurrentWeather();
  }, []);

  if (!currentWeather) {
    return <div>Loading...</div>; // O algún otro indicador de carga
  }

  return (
    <Grid container alignContent="center" justifyContent="center">
      <Card sx={{ minWidth: 260}}>
        <CardContent sx={{paddingBottom:0, paddingTop:1}}>
          <Stack direction="row" spacing={2} >
            <Stack sx={{  width: "40%" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Clima
              </Typography>
              <Typography variant="h5" component="div">
                {currentWeather.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {currentWeather.weather[0].description}
              </Typography>
            </Stack>
            <Stack alignContent="center" justifyContent="center" width="60px">
              <img
                src={`https://openweathermap.org/img/wn/${currentWeather.weather[0].icon}.png`}
                alt={currentWeather.weather[0].description}
              />
            </Stack>
          </Stack>
        </CardContent>
        <CardContent sx={{paddingBottom:0, paddingTop:0}}>
          <Stack direction="row">
            <Stack sx={{  width: "40%" }}>
              <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="h3">
                {Math.round(currentWeather.main.temp - 273.15)}°C
              </Typography>
            </Stack>
            <Stack direction="column">
              <Stack>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Detalles:
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Viento
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {currentWeather.wind.speed}m/s
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Humedad
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {currentWeather.main.humidity}%
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Presión
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {currentWeather.main.pressure}hPa
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CurrentWeather;
