import { Box } from '@mui/material';
import React from 'react'
import FooterSection from '../components/FooterSection';
import HeaderSection from '../components/HeaderSection';
import NotPage404 from '../components/NotPage404';

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
      }}
    >
      <HeaderSection />
      <NotPage404 />
      <FooterSection />
    </Box>
  );
}

export default NotFoundPage