import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import { LatLngTuple, icon } from "leaflet";
import { Grid, Typography } from "@mui/material";

function GetIcon(_iconSize: number, imageName: string) {
    return icon({
      iconUrl: require(`../../../assets/${imageName}`),
      iconSize: [_iconSize, _iconSize]
    });
  }

const MapAbout = () => {

    const position: LatLngTuple = [-15.48564, -70.13137]

    return (
        <Grid>
        <Grid>
            <Typography
                sx={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: 2
                  }}
        >
            Nos encontramos aqui:
            </Typography>
        </Grid>
        
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{padding: {xs:2,sm:3,md:5 }}}
        >
        <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{height: 500, width: "100%"}}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={GetIcon(40,"logo.png")}>
                <Popup>
                    Esta es nuestra posición.
                </Popup>
            </Marker>
        </MapContainer>,
        </Grid>
        </Grid>

    )

};
export default MapAbout;