import { Grid, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import map_project_process from "../assets/map_project_process.png";

function DescriptionSectionProjects() {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isMediumOrLargeScreen = useMediaQuery("(min-width:601px)");
  const About = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction={{ xs: "column", sm: "row" }} spacing={0}>
        <Grid item xs={12} sm={6} container>
          <img
            src={map_project_process}
            alt="Logo"
            style={{
              width: isMediumOrLargeScreen ? "90%" : "100%",
              maxHeight: isMediumOrLargeScreen ? "90%" : "100%",
              margin: "auto",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Descripción General
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "center" : "justify",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            El Proyecto  de rutas de las lineas de transporte urbano de la ciudad de 
            Juliaca es una solución automatizada diseñada para que las personas
            de dicha ciudad visualicen cuales son las rutas que siguen los microbuses
            que brindan transporte urbano utilizando OpenStreetMap y coordenadas de
            latitud y longitud para indicar de forma especifica las rutas, inicio
            y final de cada empresa de transporte urbano. Adicional se muestra una
            imagen de cada microbus, número, nombre de la empresa de transporte y
            algunas observaciones para que el usuario lo identifique de forma rapida y precisa
            cual necesita utilizar.
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Sistema de Rutas:
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Funcionalidad: </strong> Muestra las rutas de todos los microbuses.
              </li>
              <li>
                <strong>Proceso:</strong>
                <ul>
                  <li>Utilizamos la libreria leaflet junto con  OSM para visualizar e interactuar con el mapa en la WebPage .</li>
                  <li>La API extrae la información de las rutas en formato GeoJson, lineas, empresa y observaciones de la DB.</li>
                  <li>
                    En la aplicación mostramos la información de la ruta de forma visual y en otro apartado la información del microbus.
                  </li>
                  <li>
                    Cada ruta consta de 2 caminos, ida y vuelta donde cada uno esta representado con un color específico.
                  </li>
                  <li>
                    Se tiene botones para indicar cual camino queremos visualizar o sino los 2 al mismo tiempo.
                  </li>
                  <li>
                    Posee la función de poner marcadores de origen y destino de acuerdo a lo que el usuario necesite,
                    en base a lo indicado se realiza un filtrado de todos los microbuses que su ruta sea cerca al origen y al destino.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Arquitectura del Sistema
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Repositorios:</strong> Todo el servicio
                cuenta con su propio repositorio en GitHub.
              </li>
              <li>
                <strong>Despliegue sin Interfaz Gráfica:</strong> El servicio
                está diseñado para operar en un servidor sin interfaz gráfica,
                utilizando únicamente la consola y Docker.
              </li>
            </ul>
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Despliegue y CI/CD
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "left" : "left",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            <ul>
              <li>
                <strong>Docker:</strong> Facilita el despliegue y la
                administración de los servicios en diferentes entornos.
              </li>
              <li>
                <strong>GitHub:</strong> Almacena y gestiona el código fuente de
                ambos servicios.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
        paddingTop: "5vh",
      }}
      container
    >
      {About}
    </Grid>
  );
}

export default DescriptionSectionProjects;
