import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "leaflet/dist/leaflet.css"

import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Projects from './pages/Projects';
import Home from './pages/Home';
import NotFoundPage from './pages/NotFoundPage';
import Contact from './pages/Contact';
import BombermanProject from './components/projects/bomberman_project/main';
import SeaceProject from './components/projects/seace_project/main';
import CurrentWeather from './components/current_weather/CurrentWeather';
import AboutPage from './components/about/main';
import MapProject from './components/projects/map_project/main';
import TermsConditionsPage from './components/terms_conditions/main';
import PrivacyPolicyPage from './components/privacy_policy/main';
import RutaUrbana from './pages/RutaUrbana';

const router = createBrowserRouter([{
  path: '/',
  element: <Home />,
  errorElement: <NotFoundPage />
},
{
  path: '/contact',
  element: <Contact />,
},
{
  path: '/projects',
  element: <Projects />,
},
{
  path: '/projects/bomberman_project',
  element: <BombermanProject />,
},
{
  path: '/projects/seace_project',
  element: <SeaceProject />,
},
{
  path: '/projects/map_project',
  element: <MapProject />,
},
{
  path: '/weather',
  element: <CurrentWeather />,
},
{
  path: '/ruta_urbana',
  element: <RutaUrbana />,
},
{
  path: '/about',
  element: <AboutPage />,
},
{
  path: '/terms_conditions',
  element: <TermsConditionsPage />,
},
{
  path: '/privacy_policy',
  element: <PrivacyPolicyPage />,
},
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
