import { Button, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import img_404 from "../assets/404_img.webp";

function NotPage404() {
  const MiddleContent = (
    <Grid
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
      }}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center", // Asegura que el texto esté centrado
          pl: { xs: "5vw", sm: "10vw", md: "20vw", lg: "25vw" },
          pr: { xs: "5vw", sm: "10vw", md: "20vw", lg: "25vw" },
          direction: "column"
        }}
        
      >
        <Stack direction="column">
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: "2rem", // Pequeño (extra small)
                sm: "2.5rem", // Mediano (small)
                md: "3rem", // Mediano (medium)
                lg: "3.5rem", // Grande (large)
                xl: "4rem", // Extra grande (extra large)
              },
              fontWeight: "bold"
            }}
          >
            404 ERROR
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: "1.5rem", // Pequeño (extra small)
                sm: "2rem", // Mediano (small)
                md: "2rem", // Mediano (medium)
                lg: "2.5rem", // Grande (large)
                xl: "3rem", // Extra grande (extra large)
              },
            }}
          >
            Parece que te confundiste de página
          </Typography>
          <img
            src={img_404}
            alt="404_img"
            style={{
              maxWidth: "100%",
              height: "60%",
              margin: "auto",
            }}
          />
          <br></br>
          <Link to="/" style={{ textDecoration: "none", padding: "20px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                fontSize: {
                  xs: "1rem", // Pequeño (extra small)
                  sm: "1.1rem", // Mediano (small)
                  md: "1.3rem", // Mediano (medium)
                },
                padding: {
                  xs: "8px 16px", // Pequeño (extra small)
                  sm: "10px 20px", // Mediano (small)
                  md: "12px 24px", // Mediano (medium)
                },
                "&:hover": {
                  backgroundColor: "grey", // Color de fondo cuando se hace hover
                },
              }}
            >
              VOLVER AL INICIO
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        minHeight: "55vh",
        maxWidth: "100vw",
      }}
    >
      {MiddleContent}
    </Grid>
  );
}

export default NotPage404;
