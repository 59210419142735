import { Grid, Typography} from "@mui/material";

function LandSectionTermsConditions() {
  const MiddleContent = (
    <Grid
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
        position: "relative",
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          sx={{
            fontSize: {
              xs: "2rem",
              sm: "2rem",
              md: "3rem",
            },
            fontWeight: "bold",
            textAlign: "center",
            paddingBottom: "10px",
            color: "black",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
          }}
        >
          Terminos y Condiciones
        </Typography>
      </Grid>
      <Grid item xs={10} sm={8} md={6} lg={5} justifyContent="center">
        <Typography variant="body1" align="left" paragraph>
          Bienvenido a Fratelli Devs, una empresa de desarrollo software. Al acceder y utilizar nuestro sitio web, usted acepta cumplir y estar sujeto a los siguientes términos y condiciones ("Términos de Servicio"), los cuales, junto con nuestra Política de Privacidad, rigen nuestra relación con usted en relación con este sitio web. Si no está de acuerdo con alguna parte de estos términos, por favor no utilice nuestro sitio web.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>1. Uso del Sitio Web</strong><br />
          El contenido de este sitio web es solo para su información general y uso personal. Nos reservamos el derecho de cambiar, modificar, añadir o eliminar partes de estos Términos de Servicio en cualquier momento. Es su responsabilidad revisar estos Términos de Servicio periódicamente para estar al tanto de cualquier cambio.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>2. Propiedad Intelectual</strong><br />
          El contenido de este sitio web, incluyendo, pero no limitado a, textos, gráficos, logotipos, íconos, imágenes, clips de audio y video, descargas digitales y compilaciones de datos, proyectos de software, servicios para el usuario, es propiedad de Fratelli Devs y está protegido por las leyes de propiedad intelectual. Usted no puede reproducir, distribuir, modificar, transmitir, reutilizar, reenviar o usar el contenido de este sitio web para propósitos públicos o comerciales sin nuestro permiso expreso por escrito.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>3. Acceso a Repositorios y Servicios</strong><br />
          Proporcionamos enlaces a repositorios y servicios en funcionamiento que hemos desarrollado. Estos enlaces son para su conveniencia y no implican ningún tipo de respaldo por nuestra parte. No somos responsables del contenido de los sitios externos o de cualquier problema que pueda surgir de su uso.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>4. Responsabilidad Limitada</strong><br />
          En ningún caso Fratelli Devs, sus directores, empleados, socios, agentes, proveedores o afiliados, serán responsables por cualquier daño indirecto, incidental, especial, consecuente o punitivo, incluyendo sin limitación, pérdida de beneficios, datos, uso, fondo de comercio u otras pérdidas intangibles, resultantes de (i) su acceso o uso o incapacidad para acceder o usar el sitio; (ii) cualquier conducta o contenido de cualquier tercero en el sitio; (iii) cualquier contenido obtenido del sitio; y (iv) el acceso no autorizado, uso o alteración de sus transmisiones o contenido, ya sea basado en garantía, contrato, agravio (incluyendo negligencia) o cualquier otra teoría legal, ya sea que hayamos sido informados o no de la posibilidad de dichos daños.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>5. Exactitud de la Información</strong><br />
          Aunque hacemos todo lo posible para asegurar que la información en este sitio web sea correcta, no garantizamos la exactitud, integridad, idoneidad o actualidad de la información proporcionada. Usted reconoce que dicha información y materiales pueden contener inexactitudes o errores y excluimos expresamente la responsabilidad por cualquier inexactitud o error en la mayor medida permitida por la ley.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>6. Enlaces a Otros Sitios Web</strong><br />
          Nuestro sitio web puede contener enlaces a otros sitios web de interés. Sin embargo, una vez que haya utilizado estos enlaces para salir de nuestro sitio, debe tener en cuenta que no tenemos ningún control sobre ese otro sitio web. Por lo tanto, no podemos ser responsables de la protección y privacidad de cualquier información que proporcione mientras visita dichos sitios y dichos sitios no se rigen por esta declaración de privacidad.
        </Typography>
        <Typography variant="body1" align="left" paragraph>
          <strong>7. Contáctenos</strong><br />
          Si tiene alguna pregunta sobre estos Términos de Servicio, por favor contáctenos en:<br />
          fratelli.devs@gmail.com
          o llamenos al +51929747272
        </Typography>
        <Typography variant="body1" align="left">
          <strong>8. Ley Aplicable</strong><br />
          Estos Términos de Servicio se regirán e interpretarán de acuerdo con las leyes del Perú, sin dar efecto a ningún principio de conflicto de leyes. Cualquier disputa que surja en relación con estos términos será resuelta exclusivamente en los tribunales ubicados en Puno - Perú.
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
      }}
    >
      {MiddleContent}
    </Grid>
  );
}

export default LandSectionTermsConditions;
