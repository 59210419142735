import { Box, Grid, Stack, Typography } from "@mui/material";

function LandSection() {
  const MiddleContent = (
    <Grid
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
      }}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          minWidth: "100vw",
          minHeight: "45vh",
          borderRadius: 2,
          bgcolor: "rgb(95,158,160)",
          top: 0,
          right: 0,
          position: "absolute",
          zIndex: -1,
        }}
      />
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center", // Asegura que el texto esté centrado
          pl: {xs: "5vw", sm: "10vw", md:"20vw", lg:"25vw"},
          pr: {xs: "5vw", sm: "10vw", md:"20vw", lg:"25vw"},
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: {
              xs: "2rem", // Pequeño (extra small)
              sm: "2.5rem", // Mediano (small)
              md: "3rem", // Mediano (medium)
              lg: "3.5rem", // Grande (large)
              xl: "4rem", // Extra grande (extra large)
            },
            fontWeight: 'bold',
          }}
        >
          PROYECTOS DE LA EMPRESA        
          </Typography>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        minHeight: "35vh",
        maxWidth: "100vw",
      }}
    >
      {MiddleContent}
    </Grid>
  );
}

export default LandSection;
