import { Grid, Stack, Typography } from "@mui/material";
import cpp from "../assets/logos/cpp.png";
import docker from "../assets/logos/docker.png";
import github from "../assets/logos/github.png";
import grafana from "../assets/logos/Grafana.png";
import js from "../assets/logos/javaScript.png";
import kubernetes from "../assets/logos/kubernetes.png";
import psql from "../assets/logos/postgreSQL.png";
import py from "../assets/logos/python.png";
import react from "../assets/logos/react.png";

function TecnologySection() {
  const Tecnology = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction="column" spacing={10}>
        <Stack>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
            }}
          >
            Tecnologías
          </Typography>
        </Stack>
        <Stack
          direction={{ md: "column", lg: "row" }}
          alignItems="center"
          spacing={{xs: 3, sm: 3, md:3, lg:3}}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
          >
            <img
              src={cpp}
              alt="cpp_logo"
              style={{
                maxWidth: "80px",
                maxHeight: "80px",
              }}
            />

            <img
              src={docker}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />

            <img
              src={github}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center">
            <img
              src={grafana}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />

            <img
              src={js}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />

            <img
              src={kubernetes}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center">
            <img
              src={psql}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />

            <img
              src={py}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />

            <img
              src={react}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
      }}
      container
    >
      {Tecnology}
    </Grid>
  );
}

export default TecnologySection;
