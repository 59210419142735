import { Grid, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import logo_black from "../assets/logo.png";

function AboutSection() {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isMediumOrLargeScreen = useMediaQuery("(min-width:601px)");
  const About = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction={{ xs: "column", sm: "row" }} spacing={0}>
        <Grid item xs={12} sm={6} container>
          <img
            src={logo_black}
            alt="Logo"
            style={{
              maxWidth: "50%",
              height: isMediumOrLargeScreen ? "200px" : "80%",
              margin: "auto",
              paddingLeft: isSmallScreen ? "0" : "35%",
            }}
          />
          {/* <Typography variant="body1">Logo en negro</Typography> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: isSmallScreen ? "center" : "center",
              paddingRight: isSmallScreen ? "0" : "35%",
              //paddingLeft: isSmallScreen ? '20%' : '20%',
            }}
          >
            Sobre Nosotros
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: isSmallScreen ? "center" : "justify",
              paddingRight: isSmallScreen ? "10%" : "40%",
              paddingLeft: isSmallScreen ? "10%" : "0",
            }}
          >
            Bienvenido a Fratelli, donde la innovación y la excelencia se unen
            para ofrecerte soluciones de software avanzadas. Desde nuestros
            inicios, nos hemos dedicado a convertir ideas en realidades
            digitales, impulsando el crecimiento y el éxito empresarial en todos
            los sectores. Nos enorgullece nuestra pasión por la tecnología y
            nuestro enfoque centrado en el cliente. Cada proyecto es una
            oportunidad para superar expectativas y establecer nuevas fronteras
            en el desarrollo de software. Nos esforzamos por entender las
            necesidades únicas de cada cliente y diseñar soluciones
            personalizadas que impulsen la eficiencia, la innovación y el
            crecimiento empresarial.
          </Typography>
        </Grid>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
        paddingTop: "10%",
      }}
      container
    >
      {About}
    </Grid>
  );
}

export default AboutSection;
