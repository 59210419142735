import { Box } from "@mui/material";

import Map from "../components/open_street_map/Map";
import FooterSection from "../components/FooterSection";

const RutaUrbana = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
      }}
    >
      <Map />
      <FooterSection />
    </Box>
  );
};

export default RutaUrbana;
