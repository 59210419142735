import { Grid, Stack, Typography } from "@mui/material";
import github_logo from "../assets/logos/github.png";
import react_logo from "../assets/logos/react.png";
import openstreetmap_logo from "../assets/logos/openstreetmap.png";
import postgresql_logo from "../assets/logos/postgreSQL.png";
import docker_logo from "../assets/logos/docker.png";
import nodejs_logo from "../assets/logos/nodejs.png";
import ts_logo from "../assets/logos/typescript.png";

function TecnologySectionProjects() {
  const Tecnology = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction="column" spacing={10}>
        <Stack>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
            }}
          >
            Tecnologías Utilizadas
          </Typography>
        </Stack>
        <Stack
          direction={{ md: "column", lg: "row" }}
          alignItems="center"
          spacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
          >
            <img
              src={react_logo}
              alt="cpp_logo"
              style={{
                maxWidth: "90px",
                maxHeight: "90px",
              }}
            />

            <img
              src={openstreetmap_logo}
              alt="cpp_logo"
              style={{
                maxWidth: "60px",
                maxHeight: "60px",
              }}
            />

            <img
              src={postgresql_logo}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
          >
            <img
              src={docker_logo}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
            <img
              src={nodejs_logo}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
          >
            <img
              src={github_logo}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
            <img
              src={ts_logo}
              alt="cpp_logo"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
      }}
      container
    >
      {Tecnology}
    </Grid>
  );
}

export default TecnologySectionProjects;
