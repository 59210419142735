import {
  Grid,
  Stack,
  Typography,
  IconButton,
  Divider,
  Link as MuiLink,
  Box,
} from "@mui/material";

import logo_white from "../assets/logo_name_white.png";
import cellphone from "../assets/mobile.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

function FooterSection() {
  const Footer = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        style={{ backgroundColor: "black", minHeight: "20vh", width: "100vw" }}
      >
        <Grid
      item
      xs={12}
      sm={4}
      style={{ color: "white", padding: "20px", display: 'flex', justifyContent: 'center' }}
    >
      <Box textAlign="left">
        <Typography variant="h5" paddingBottom={2}>
          Nuestros Servicios
        </Typography>
        <p
          style={{
            paddingBottom: "1px",
            display: "block",
            textDecoration: "none",
            color: "white",
          }}
        >
          Desarrollo de Software Personalizado
        </p>
       
        <p
          style={{
            paddingBottom: "1px",
            display: "block",
            textDecoration: "none",
            color: "white",
          }}
        >
          Desarrollo de Aplicaciones Web y Móviles
        </p>
        
        <p
          style={{
            paddingBottom: "1px",
            display: "block",
            textDecoration: "none",
            color: "white",
          }}
        >
          Mantenimiento y Soporte de Software
        </p>
       
        <p
          style={{
            paddingBottom: "1px",
            display: "block",
            textDecoration: "none",
            color: "white",
          }}
        >
          Integración de Sistemas
        </p>
      </Box>
    </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{ color: "white", textAlign: "center", padding: "20px" }}
        >
          <img
            src={logo_white}
            alt="cpp_logo"
            style={{
              maxWidth: "300px",
              maxHeight: "300px",
              width: "100%",
              height: "auto",
            }}
          />
          <Typography variant="inherit" paddingBottom={2}>
            Aprende acerca de la empresa
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{ color: "white", textAlign: "center", padding: "20px" }}
        >
          <Typography variant="h5" paddingBottom={2}>
            Contacto
          </Typography>
          <p
            style={{
              
              paddingBottom: "2px",
              display: "block",
              textDecoration: "none",
              color: "white",
            }}
          >
            fratelli.devs@gmail.com
          </p>
          
          
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{paddingBottom: 2}}>
              <img
                src={cellphone}
                alt="cell"
                style={{
                  maxWidth: "25px",
                  maxHeight: "25px",
                }}
              />
              +51929747272
            </Stack>
          
          
          <Typography variant="body1" sx={{ marginRight: "0.5rem" }}>
            Síguenos en:
          </Typography>
          <IconButton
            component="a"
            href="https://www.facebook.com/profile.php?id=61560394479857"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.linkedin.com/company/fratelli-devs/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ color: "gray", textAlign: "left" }}>
          <Stack
            direction="row"
            alignItems="top"
            justifyContent="center"
            spacing={{ xs: 1, sm: 2 }}
          >
            <Typography variant="inherit">
              Copyright © 2024 Fratelli Devs
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "grey.500" }}
            />
            <Typography variant="inherit">
              <MuiLink
                component={Link}
                to="/terms_conditions"
                sx={{ color: "inherit", textDecoration: "none" }}
              >
                Términos y Condiciones
              </MuiLink>
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "grey.500" }}
            />
            <Typography variant="inherit">
              <MuiLink
                component={Link}
                to="/privacy_policy"
                sx={{ color: "inherit", textDecoration: "none" }}
              >
                Políticas de Privacidad
              </MuiLink>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "30vh",
        paddingTop: "100px",
        maxWidth: "100vw",
      }}
      container
    >
      {Footer}
    </Grid>
  );
}

export default FooterSection;
