import axios from "axios";

export const handleButtonClickSendMetric = async (id: string, url: string): Promise<void> => {
  try {
    const response = await axios.post("https://metrics.api.fratellidevs.com/send-metrics", {
      message: 'ok',
      id: id,
      url: url,
    });
    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error sending POST request:", error);
  }
};
