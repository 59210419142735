import {
  Grid,
  Stack,
  Typography,
  Button,
  ImageList,
  ImageListItem,
} from "@mui/material";
import github_logo from "../assets/logos/github.png";
import bomberman_process_1 from "../assets/bomber_process_1.png";
import bomberman_process_2 from "../assets/bomber_process_2.png";
import bomberman_process_3 from "../assets/bomber_process_3.png";
import bomberman_process_4 from "../assets/bomber_process_4.png";
import bomberman_process_5 from "../assets/bomber_process_5.png";
import bomberman_process_6 from "../assets/bomber_process_6.png";
import { handleButtonClickSendMetric } from "../../../HandleButtonClickSendMetric";

function StandardImageList() {
  return (
    <ImageList
      sx={{ width: { sm: 500, md: 700, lg: 900 }, height: 500 }}
      cols={2}
      rowHeight={250}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=400&h=300&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=400&h=300&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

function RepoSectionProjects() {
  const Repo = (
    <Grid
      sx={{}}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction="column" spacing={10}>
        <Stack>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
            }}
          >
            Imagenes del Proyecto
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={4}
          alignItems="center"
        >
          <StandardImageList />
        </Stack>
        <Stack>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
            }}
          >
            Repositorio del Proyecto
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={4}
          alignItems="center"
          justifyContent="center"
        >
          <a href="https://github.com/Just-for-fun-Developers/Bomberman" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "grey",
              fontSize: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1.3rem",
              },
              padding: {
                xs: "8px 16px",
                sm: "10px 20px", 
                md: "12px 24px", 
              },
              "&:hover": {
                backgroundColor: "rgb(192,192,192)", 
              },
            }}
            onClick={() => handleButtonClickSendMetric("button_bomberman_git", "projects/bomberman_project")}  
          >
            Repositorio del Proyecto
            <img
              src={github_logo}
              height="40px"
              width="70px"
              alt="logo github"
            />
          </Button>
          </a>
        </Stack>
      </Stack>
    </Grid>
  );
  return (
    <Grid
      sx={{
        minHeight: "40vh",
      }}
      container
    >
      {Repo}
    </Grid>
  );
}

const itemData = [
  {
    img: bomberman_process_1,
    title: "bomberman_process_1",
  },
  {
    img: bomberman_process_2,
    title: "bomberman_process_2",
  },
  {
    img: bomberman_process_3,
    title: "bomberman_process_3",
  },
  {
    img: bomberman_process_4,
    title: "bomberman_process_4",
  },
  {
    img: bomberman_process_5,
    title: "bomberman_process_5",
  },
  {
    img: bomberman_process_6,
    title: "bomberman_process_6",
  },
];

export default RepoSectionProjects;
