import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, CardMedia, Grow } from '@mui/material';
import axios from 'axios';
import seace from "../assets/seace.jpg";

interface Data {
  nomenclatura: string;
  entidad: string;
  descripcion_objeto: string;
  fecha_hora_publicacion: string;
}

const SeaceComponent: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [show, setShow] = useState<boolean>(true);
  useEffect(() => {
    const fetchTotalRows = async () => {
      try {
        const response = await axios.get('https://seaceconvs.api.fratellidevs.com/total-rows');
        setTotalRows(response.data.totalRows);
      } catch (error) {
        console.error('Error fetching total rows:', error);
      }
    };
    
    const fetchData = async (offset: number, limit: number) => {
      try {
        const response = await axios.get('https://seaceconvs.api.fratellidevs.com/data', { params: { offset, limit } });
        setData(prevData => {
          const newData = [...prevData, ...response.data];
          if (newData.length > 3) {
            newData.splice(0, newData.length - 3); // Keep only the last 3 elements
          }
          return newData;
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTotalRows();
    fetchData(currentOffset, 3); // Fetch the first 3 elements initially

    const intervalId = setInterval(() => {
      setShow(false); // Hide cards before updating
      setTimeout(() => {
        setCurrentOffset(prevOffset => {
          const newOffset = (prevOffset + 1) % totalRows;
          fetchData(newOffset, 1); // Fetch one more element
          setShow(true); // Show cards after updating
          return newOffset;
        });
      }, 500); // Adjust timing to match the animation
    }, 5000); // 5000 ms = 5 seconds

    return () => clearInterval(intervalId);
  }, [totalRows, currentOffset]); // Only run once on mount, and when totalRows changes

  return (
    <div style={{ padding: '0 25%' }}>
      <Grid spacing={1} justifyContent="center">
        {data.map((row, index) => (
          <Grow in={show} timeout={500} key={index}>
            <Grid item xs={12} sm={12} md={6} style={{margin: '5px 0'}}>
              <Card style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                <CardContent style={{ flex: 1 }}>
                  <Typography variant="h6" component="div" style={{ fontSize: '0.8rem', marginBottom: '5px' }}>
                    {row.entidad}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{ fontSize: '0.7rem', marginBottom: '5px' }}>
                    {row.nomenclatura}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{ fontSize: '0.7rem', marginBottom: '5px' }}>
                    {row.descripcion_objeto}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{ fontSize: '0.7rem' }}>
                    {new Date(row.fecha_hora_publicacion).toLocaleString()}
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  image={seace}
                  alt="seace logo"
                  style={{ width: '50px', height: '50px', marginLeft: '10px' }}
                />
              </Card>
            </Grid>
          </Grow>
        ))}
      </Grid>
    </div>
  );
};

export default SeaceComponent;
