import { Box } from "@mui/material";

import HeaderSection from "../components/HeaderSection";
import FooterSection from "../components/FooterSection";
import ProjectsSectionProjects from "../components/ProjectsSectionProjects";
import LandSectionProjects from "../components/LandSectionProjects";

const Main = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
      }}
    >
      <HeaderSection />
      <LandSectionProjects />
      <ProjectsSectionProjects />
      <FooterSection />
    </Box>
  );
};

export default Main;
