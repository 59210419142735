import React, { useState, useEffect, useCallback } from "react";
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker,
  useMap,
  useMapEvent,
} from "react-leaflet";
import { Close } from "@mui/icons-material";
import { LatLngTuple, icon } from "leaflet";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
  Stack,
  Divider,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "leaflet-ant-path";
import { antPath } from "leaflet-ant-path";
import axios from "axios";
import logo from "../../assets/logo_white.png";
import origin_img from "../../assets/origin.png";
import destiny_img from "../../assets/destiny.png";
import start_img from "../../assets/start_flag.png";
import finish_img from "../../assets/finish_flag.png";
import markers_img from "../../assets/markers.png";

import markersData from "./markersData.json";

import { handleButtonClickSendMetric } from "../HandleButtonClickSendMetric";

interface CoorMap {
  lat: number;
  lng: number;
}

// Definimos el tipo de datos del JSON
type PolylineData = {
  [key: string]: {
    company: string;
    image: string;
    number: string;
    routes: LatLngTuple[][];
    description: string;
  };
};

const colors = ["blue", "red"];

function GetIcon(_iconSize: number, imageName: string, anchorNum: number) {
  return icon({
    iconUrl: require(`../../assets/${imageName}`),
    iconSize: [_iconSize, _iconSize],
    iconAnchor: [_iconSize / anchorNum, _iconSize],
  });
}

const PolylinePaths = ({
  activeKey,
  polylineData,
  showOnly,
}: {
  activeKey: string | null;
  polylineData: PolylineData;
  showOnly: "ida" | "vuelta" | "ambos";
}) => {
  const map = useMap();

  useEffect(() => {
    if (!activeKey) return;

    const paths = polylineData[activeKey].routes.map((polyline, index) => {
      return antPath(polyline, {
        color: colors[index % colors.length],
        delay: 700,
        weight: 5,
        opacity: 0.6,
        reverse: false,
        dashArray: [5, 100],
        pulseColor: "#FFFFFF",
      }).addTo(map);
    });
    if (showOnly === "ida") {
      map.removeLayer(paths[1]);
    }
    if (showOnly === "vuelta") {
      map.removeLayer(paths[0]);
    }
    return () => {
      paths.forEach((path) => map.removeLayer(path));
    };
  }, [activeKey, polylineData, map, showOnly]);

  return null;
};

const SetMarkers = ({
  origin,
  destiny,
  posUserMarker,
  setOrigin,
  setDestiny,
  setPosUserMarker,
}: {
  origin: LatLngTuple | null;
  destiny: LatLngTuple | null;
  posUserMarker: string | null;
  setOrigin: (coord: LatLngTuple) => void;
  setDestiny: (coord: LatLngTuple) => void;
  setPosUserMarker: (marker: string) => void;
}) => {
  const [contextMenu, setContextMenu] = useState<{
    show: boolean;
    pos: LatLngTuple;
    point: { lat: number; lng: number };
  }>({ show: false, pos: [0, 0], point: { lat: 0, lng: 0 } });

  const map = useMap();

  const startHoldEvent = useCallback(
    (latLng: LatLngTuple) => {
      let timeout: NodeJS.Timeout;

      const handleEndHoldEvent = () => {
        clearTimeout(timeout);
        map.getContainer().removeEventListener("touchend", handleEndHoldEvent);
        map.off("mouseup", handleEndHoldEvent);
      };

      map.on("mouseup", handleEndHoldEvent);
      map.getContainer().addEventListener("touchend", handleEndHoldEvent);

      timeout = setTimeout(() => {
        const layerPoint = map.latLngToContainerPoint(latLng);
        setContextMenu({
          show: true,
          pos: latLng,
          point: { lat: layerPoint.y, lng: layerPoint.x },
        });
        handleEndHoldEvent();
      }, 2000);
    },
    [map]
  );

  useMapEvent("mousedown", (e) => {
    const latLng: LatLngTuple = [e.latlng.lat, e.latlng.lng];
    startHoldEvent(latLng);
  });

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      const touch = e.touches[0];
      const latLng = map.containerPointToLatLng([touch.clientX, touch.clientY]);
      startHoldEvent([latLng.lat, latLng.lng]);
    };

    map.getContainer().addEventListener("touchstart", handleTouchStart);

    return () => {
      map.getContainer().removeEventListener("touchstart", handleTouchStart);
    };
  }, [map, startHoldEvent]);

  const handleSetOrigin = () => {
    setOrigin(contextMenu.pos);
    setPosUserMarker("person_flag.png");
    setContextMenu({ show: false, pos: [0, 0], point: { lat: 0, lng: 0 } });
  };

  const handleSetDestiny = () => {
    setDestiny(contextMenu.pos);
    setContextMenu({ show: false, pos: [0, 0], point: { lat: 0, lng: 0 } });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({ show: false, pos: [0, 0], point: { lat: 0, lng: 0 } });
  };

  return (
    <>
      {origin && (
        <Marker position={origin} icon={GetIcon(40, "origin.png", 2)}>
          <Popup>Origen</Popup>
        </Marker>
      )}
      {destiny && (
        <Marker position={destiny} icon={GetIcon(40, "destiny.png", 2)}>
          <Popup>Destino</Popup>
        </Marker>
      )}
      {contextMenu.show && (
        <Grid
          style={{
            position: "absolute",
            top: `${contextMenu.point.lat}px`,
            left: `${contextMenu.point.lng}px`,
            backgroundColor: "rgb(96,96,96)",
            borderRadius: "10%",
            padding: "3px",
            zIndex: 1000,
            textAlign: "center",
          }}
        >
          <Button
            sx={{
              position: "absolute",
              top: "2px",
              right: "2px",
              minWidth: "auto",
              height: "20px",
              padding: "1px",
            }}
            onClick={handleCloseContextMenu}
          >
            <Close sx={{ color: "#fff" }} />
          </Button>
          <Stack
            direction="column"
            divider={<Divider sx={{ borderColor: "#fff" }} />}
          >
            <br></br>
            <Button sx={{ color: "rgb(0,204,0)" }} onClick={handleSetOrigin}>
              Origen
            </Button>
            <Button
              sx={{ color: "rgb(255,102,102)" }}
              onClick={handleSetDestiny}
            >
              Destino
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  );
};

const Map = () => {
  const [stateCoords, setCoordsMap] = useState<CoorMap | null>(null);
  const [polylineData, setPolylineData] = useState<PolylineData | null>(null);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [activeLocation, setActiveLocation] = useState<boolean | null>(null);
  const [showOnly, setShowOnly] = useState<"ida" | "vuelta" | "ambos">("ambos");
  const [coordStart, setCoordStart] = useState<LatLngTuple | null>(null);
  const [coordFinish, setCoordFinish] = useState<LatLngTuple | null>(null);
  const [origin, setOrigin] = useState<LatLngTuple | null>(null);
  const [destiny, setDestiny] = useState<LatLngTuple | null>(null);
  const [searchRoutes, setSearchRoutes] = useState<string[]>([]);
  const [posUserMarker, setPosUserMarker] = useState<string>("origin.png");
  const [activeSuggest, setActiveSuggest] = useState<boolean>(false);
  const [showMarkers, setShowMarkers] = useState<boolean>(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setCoordsMap({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setOrigin([position.coords.latitude, position.coords.longitude]);
        setActiveLocation(true);
      },
      function () {
        setCoordsMap({
          lat: -15.4991,
          lng: -70.1339,
        });
      },
      {
        enableHighAccuracy: true,
      }
    );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://seaceconvs.api.fratellidevs.com/routes"
        );
        const parsedData: PolylineData = {};

        response.data.forEach((route: any) => {
          parsedData[route.number] = {
            company: route.company,
            image: route.image,
            number: route.number,
            description: route.description,
            routes: route.routes.features.map((feature: any) =>
              feature.geometry.coordinates.map((coord: number[]) => [
                coord[1], // latitud
                coord[0], // longitud
              ])
            ),
          };
        });

        setPolylineData(parsedData);
        const routes = Object.keys(parsedData);
        setSearchRoutes(routes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (polylineData && activeKey) {
      setCoordStart(polylineData[activeKey].routes[0][0]);
      setCoordFinish(
        polylineData[activeKey].routes[0][
          polylineData[activeKey].routes[0].length - 1
        ]
      );
    }
    if (origin && destiny) {
      setActiveSuggest(true);
    }
  }, [polylineData, activeKey, coordStart, coordFinish, origin, destiny]);

  if (!stateCoords || !polylineData) {
    return <div>Loading...</div>;
  }

  const position: LatLngTuple = [stateCoords.lat, stateCoords.lng];
  const handleButtonClick = (key: string) => {
    setActiveKey(key === activeKey ? null : key);
  };

  const handleShowOnly = (type: "ida" | "vuelta" | "ambos") => {
    setShowOnly(type);
  };
  const handleShowMarkers = () => {
    setShowMarkers(!showMarkers);
  };

  const handleButtonSearchRoutes = async (origin: any, destiny: any) => {
    try {
      if (!origin && activeLocation) {
        origin = position;
      }

      const apiUrl1 = `https://seaceconvs.api.fratellidevs.com/routes/nearby?lng=${origin[1]}&lat=${origin[0]}&radius=0.005`;
      const apiUrl2 = `https://seaceconvs.api.fratellidevs.com/routes/nearby?lng=${destiny[1]}&lat=${destiny[0]}&radius=0.005`;

      const response1 = await fetch(apiUrl1);
      const response2 = await fetch(apiUrl2);

      if (response1.ok && response2.ok) {
        const data1 = await response1.json();
        const data2 = await response2.json();

        const routes1 = data1.map((item: any) => item.number);
        const routes2 = data2.map((item: any) => item.number);

        const commonRoutes = routes1.filter((route_number: string) =>
          routes2.includes(route_number)
        );

        setSearchRoutes(commonRoutes);
        setActiveKey(null);
      } else {
        console.error("Error al obtener datos:", response1.statusText);
        console.error("Error al obtener datos:", response2.statusText);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MapContainer
          center={position}
          zoom={12}
          scrollWheelZoom={true}
          style={{ height: 450, width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {showMarkers &&
            markersData.map((marker, index) => (
              <Marker
                key={index}
                position={[marker.position[0], marker.position[1]]}
                icon={GetIcon(40, marker.icon, 2)}
              >
                <Popup>{marker.popupText}</Popup>
              </Marker>
            ))}
          {activeLocation && (
            <Grid>
              <Marker position={position} icon={GetIcon(40, posUserMarker, 2)}>
                <Popup>Tu Ubicación</Popup>
              </Marker>
            </Grid>
          )}
          {activeKey && coordStart && coordFinish && (
            <Grid>
              <Marker
                position={coordStart}
                icon={GetIcon(40, "start_flag.png", 1.4)}
              >
                <Popup>Inicio</Popup>
              </Marker>
              <Marker
                position={coordFinish}
                icon={GetIcon(40, "finish_flag.png", 1.4)}
              >
                <Popup>Final</Popup>
              </Marker>
            </Grid>
          )}

          <PolylinePaths
            activeKey={activeKey}
            polylineData={polylineData}
            showOnly={showOnly}
          />
          <SetMarkers
            origin={origin}
            destiny={destiny}
            posUserMarker={posUserMarker}
            setOrigin={setOrigin}
            setDestiny={setDestiny}
            setPosUserMarker={setPosUserMarker}
          />
          <Grid
            style={{
              position: "absolute",
              top: `5px`,
              right: `5px`,
              backgroundColor: "rgb(96,96,96)",
              borderRadius: "10%",
              padding: "3px",
              zIndex: 1000,
              textAlign: "center",
            }}
          >
            <img src={logo} height="45px" width="45px" alt="logo github" />
          </Grid>
          <Button
            onClick={() => {
              handleShowMarkers();
              handleButtonClickSendMetric("button_ruta_Markers", "/ruta_urbana");
            }}
            style={{
              position: "absolute",
              top: `10px`,
              left: `50px`,
              backgroundColor: "rgb(0,153,0)",
              borderRadius: "15%",
              padding: "1px",
              zIndex: 1000,
              textAlign: "center",
              border: "2px solid black",
            }}
          >
            <img
              src={markers_img}
              height="45px"
              width="45px"
              alt="markers icon"
            />
          </Button>
        </MapContainer>
      </Grid>
      <Grid
        sx={{
          flexGrow: 1,
          minWidth: "100vw",
          minHeight: "4vh",
          borderRadius: 2,
          bgcolor: "rgb(224,224,224)",
          position: "relative",
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        item
        sm={12}
        justifyContent="center"
        alignItems="center"
      >
        <Typography sx={{ margin: 0.5 }} textAlign="center">
          <Button
            onClick={() => {
              handleShowOnly("ida");
              handleButtonClickSendMetric("button_ruta_urbana_ida", "/ruta_urbana");  
            }}
            color={showOnly === "ida" ? "primary" : "inherit"}
          >
            Ida
          </Button>
        </Typography>
        <Box
          sx={{
            width: 40,
            height: 10,
            borderRadius: 2,
            bgcolor: "blue",
            margin: 0,
          }}
        />
        <Typography sx={{ margin: 0.5 }} textAlign="center">
          <Button
            onClick={() =>{ 
              handleShowOnly("vuelta");
              handleButtonClickSendMetric("button_ruta_urbana_vuelta", "/ruta_urbana");
            }}
            color={showOnly === "vuelta" ? "primary" : "inherit"}
          >
            Vuelta
          </Button>
        </Typography>
        <Box
          sx={{
            width: 40,
            height: 10,
            borderRadius: 2,
            bgcolor: "red",
            margin: 0,
          }}
        />
        <Typography sx={{ margin: 0.5 }} textAlign="center">
          <Button
            onClick={() => {
              handleShowOnly("ambos");
              handleButtonClickSendMetric("button_ruta_urbana_ambos", "/ruta_urbana");
            }}
            color={showOnly === "ambos" ? "primary" : "inherit"}
          >
            Ambos
          </Button>
        </Typography>
        <Box
          sx={{
            width: 30,
            height: 10,
            borderRadius: 2,
            bgcolor: "blue",
            margin: 0,
          }}
        />
        <Box
          sx={{
            width: 30,
            height: 10,
            borderRadius: 2,
            bgcolor: "red",
            marginRight: 2,
          }}
        />
      </Grid>
      <Grid item justifyContent="center" xs={6}>
        <Grid item xs={12} marginBottom={0.5}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              handleButtonSearchRoutes(origin, destiny);
              handleButtonClickSendMetric("button_ruta_urbana_sugerir_micros", "/ruta_urbana");
            }}
            disabled={!activeSuggest}
          >
            Sugerir Microbuses
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box
            border={1}
            borderColor="primary.main"
            borderRadius={2}
            p={2}
            style={{
              height: "300px", // Define la altura del contenedor
              overflowY: "auto", // Habilita el scroll vertical
            }}
          >
            <Grid container spacing={2} direction="column">
              {polylineData &&
                Object.keys(polylineData)
                  .filter((key) =>
                    searchRoutes.includes(polylineData[key].number)
                  )
                  .map((key) => (
                    <Grid key={key} item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleButtonClick(key);
                          handleButtonClickSendMetric("button_ruta_urban_micro_"+(key), "/ruta_urbana");
                        }}

                      >
                        {`${polylineData[key].number}`}
                      </Button>
                    </Grid>
                  ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {activeKey && (
        <Grid item xs={6}>
          <Card sx={{ maxWidth: { md: 600 }, margin: "0 auto" }}>
            <Grid container direction={{ xs: "column", md: "row" }}>
              <Grid item md={6}>
                <CardMedia
                  component="img"
                  sx={{ minWidth: { md: 200 } }}
                  image={polylineData[activeKey].image}
                  alt={`Imagen de la ruta ${polylineData[activeKey].number}`}
                />
              </Grid>
              <Grid item md={6}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {`${polylineData[activeKey].number}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${polylineData[activeKey].company}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.alert"
                    sx={{ fontStyle: "italic" }}
                  >
                    {`${polylineData[activeKey].description}`}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid item xs={12} sm={6}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <strong>¿Qué es Ruta Urbana?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Es una aplicación desarrollada por la empresa{" "}
                <strong>Fratelli Devs,</strong> la aplicación contiene una
                recopilación cuidadosa y aproximada del camino que recorren los
                microbuses en la ciudad de <strong>JULIACA</strong>, tanto el
                recorrido de ida como de vuelta. El uso es para todo aquel
                ciudadano que quiera encontrar el microbus indicado para llegar
                a su destino y conocer el lugar en donde debe tomar el microbus
                según su ubicación en el mapa.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>
                <strong>Instrucciones de Uso</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div">
                <ul>
                  <li>
                    Para una mejor experiencia debe habilitar los permisos de
                    ubicación.
                  </li>
                  <li>
                    Al cargar la página web se muestran una lista de los
                    microbuses que transitan en la ciudad de JULIACA.
                  </li>
                  <li>
                    Al hacer click en alguno de los botones que representan los
                    microbuses se muestra:
                    <ul>
                      <li>La ruta de ida y vuelta en el mapa</li>
                      <li>Una fotografía modelo del microbus</li>
                      <li>El número de la linea (microbus)</li>
                      <li>Nombre de la Empresa de Transportes</li>
                      <li>Observaciones</li>
                    </ul>
                  </li>
                </ul>
              </Typography>
              <Typography component="div">
                <Typography variant="h6" component="div" gutterBottom>
                  ICONOS EN EL MAPA
                </Typography>
                <ul>
                  <li>
                    Si Logra habilitar la ubicación se muestra una paleta de
                    color VERDE
                    <Box component="span">
                      <img
                        src={origin_img}
                        alt="Paleta Verde"
                        style={{
                          width: "25px",
                          height: "25px",
                          verticalAlign: "middle",
                        }}
                      />
                    </Box>
                    que significa el origen y su ubicación actual.
                  </li>
                  <li>
                    Existe una paleta de color ROJO
                    <Box component="span">
                      <img
                        src={destiny_img}
                        alt="Paleta Roja"
                        style={{
                          width: "25px",
                          height: "25px",
                          verticalAlign: "middle",
                        }}
                      />
                    </Box>
                    que significa el destino referente a donde se quiere llegar.
                    Para su uso lea{" "}
                    <em style={{ color: "purple" }}>
                      USO DE SUGERIR MICROBUSES
                    </em>
                  </li>
                  <li>
                    Al habilitar una ruta, se muestran dos banderas con
                    iniciales, la
                    <Box component="span">
                      <img
                        src={start_img}
                        alt="start_img"
                        style={{
                          width: "25px",
                          height: "25px",
                          verticalAlign: "middle",
                        }}
                      />
                    </Box>
                    significa el Inicio de ruta, y la
                    <Box component="span">
                      <img
                        src={finish_img}
                        alt="finish_img"
                        style={{
                          width: "25px",
                          height: "25px",
                          verticalAlign: "middle",
                        }}
                      />
                    </Box>
                    significa el Final de la ruta, indicando que el microbus
                    viaja desde el inicio al final y viceversa.
                  </li>
                  <li>
                    Al habilitar una ruta, se muestran también 2 lineas, una
                    linea de color{" "}
                    <strong style={{ color: "blue" }}>AZUL</strong> (el camino
                    de ida), y una linea de color
                    <strong style={{ color: "red" }}> ROJO</strong> (el camino
                    de vuelta).Existen botones con estos nombres debajo del mapa
                    (ida, vuelta y ambos) que al accionarlos muestran el camino
                    que representan según el nombre. Se debe observar que las
                    lineas graficadas en el mapa contienen una{" "}
                    <strong>ANIMACIÓN </strong> que indica la dirección en la
                    que se dirige el microbus ya sea de ida o vuelta del
                    recorrido.
                  </li>
                </ul>
              </Typography>
              <Typography component="div">
                <Typography variant="h6" component="div" gutterBottom>
                  MOSTRAR LUGARES CONCURRENTES EN JULIACA
                </Typography>
                <ul>
                  <li>
                   Presione el botón con la imagen
                    <Box component="span" style={{ marginLeft: "5px", marginRight:"5px" }}>
                      <img
                        src={markers_img}
                        alt="markers"
                        style={{
                          width: "25px",
                          height: "25px",
                          verticalAlign: "middle",
                        }}
                      />
                    </Box>
                    dentro del marco del mapa para activar o desactivar esta funcionalidad. Esta funcionalidad te muestra
                    marcadores de los lugares concurrentes en la ciudad de
                    JULIACA para una mejor familiarización con la ciudad.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <strong>Uso de SUGERIR MICROBUSES</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Para poder usar la funcionalidad de SUGERIR MICROBUSES se deben
                posicionar en el mapa dos iconos (EL ORIGEN{" "}
                <Box component="span">
                  <img
                    src={origin_img}
                    alt="origin_img"
                    style={{
                      width: "25px",
                      height: "25px",
                      verticalAlign: "middle",
                    }}
                  />
                </Box>{" "}
                Y EL DESTINO{" "}
                <Box component="span">
                  <img
                    src={destiny_img}
                    alt="destiny_img"
                    style={{
                      width: "25px",
                      height: "25px",
                      verticalAlign: "middle",
                    }}
                  />
                </Box>
                ). Al brindar permisos de ubicación al cargar la página web, se
                toma la ubicación actual como el origen, pero esto puede ser
                cambiado.
              </Typography>
              <Typography>
                Para posicionar ya sea el <strong>ORIGEN</strong> o el{" "}
                <strong>DESTINO</strong> en el <strong>MAPA</strong>, si esta en
                un computador (<em>mantener el click izquierdo</em>) o si esta
                en un celular (<em>mantener un touch</em>) alrededor de 3 o 4
                segundos dentro del mapa, esto desplegará dos botones para
                elegir si la ubicación donde hizo el click o el touch será el
                ORIGEN o el DESTINO. Es importante saber que para poder usar la
                función
                <em> SUGERIR MICROBUSES</em> deben estar habilitado ambos
                (ORIGEN Y DESTINO). Luego se debe accionar el BOTÓN de color{" "}
                <strong style={{ color: "purple" }}>MORADO</strong>(
                <em style={{ color: "purple" }}>SUGERIR MICROBUSES</em>).
                Dependiendo de el ORIGEN y el DESTINO se hace una búsqueda de
                los microbuses que tienen un recorrido que pase cerca del ORIGEN
                y DESTINO establecidos.
              </Typography>
              <br />
              <Typography>
                <strong>NOTA:</strong> Si no aparece ninguna sugerencia, es
                porque no se encontró ningún microbus que pase cerca por el
                punto de ORIGEN y el punto de DESTINO, se le sugiere que cambie
                un poco la posición del ORIGEN o del DESTINO y vuelva a hacer la
                búsqueda.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Map;
