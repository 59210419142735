import { Box } from "@mui/material";

import HeaderSection from "../../HeaderSection";
import FooterSection from "../../FooterSection";
import LandSectionProjects from "./components/LandSectionProjects";
import DescriptionSectionProjects from "./components/DescriptionSectionProjects";
import TecnologySectionProjects from "./components/TecnologySectionProjects";
import RepoSectionProjects from "./components/RepoSectionProjects";

const Main = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
      }}
    >
      <HeaderSection />
      <LandSectionProjects />
      <DescriptionSectionProjects />
      <TecnologySectionProjects />
      <RepoSectionProjects />
      <FooterSection />
    </Box>
  );
};

export default Main;
