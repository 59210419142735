import { Box } from "@mui/material";

import HeaderSection from "../HeaderSection";
import FooterSection from "../FooterSection";
import LandSectionPrivacyPolicy from "./components/LandSectionPrivacyPolicy";


const Main = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
      }}
    >
      <HeaderSection />
      <LandSectionPrivacyPolicy />
      <FooterSection />
    </Box>
  );
};

export default Main;
