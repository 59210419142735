import { Grid, Stack, Typography, Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import seace_img from "../assets/seace.jpg";

function LandSectionProjects() {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isMediumOrLargeScreen = useMediaQuery("(min-width:601px)");
  const MiddleContent = (
    <Grid
      sx={{
        flexGrow: 1,
        maxWidth: "100vw",
      }}
      item
      container
      md={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          minWidth: "100vw",
          minHeight: "45vh",
          borderRadius: 2,
          bgcolor: "rgb(255,127,80)",
          top: 0,
          right: 0,
          position: "absolute",
          zIndex: -1,
        }}
      />

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={0}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={10} sm={6} md={5}>
          <Typography
            sx={{
              fontSize: {
                xs: "2rem", // Pequeño (extra small)
                sm: "2rem", // Mediano (small)
                md: "2.5rem", // Mediano (medium)
                lg: "2.5rem", // Grande (large)
                xl: "3.3rem", // Extra grande (extra large)
              },
              fontWeight: "bold",
              textAlign: "center",
              paddingLeft: isSmallScreen ? "0" : "25%",
            }}
          >
            Proyecto Notificador de Convocatorias del Estado Peruano SEACE
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5} container>
          <img
            src={seace_img}
            alt="Logo"
            style={{
              maxWidth: "70%",
              height: isMediumOrLargeScreen ? "80%" : "60%",
              margin: "auto",
              paddingRight: isSmallScreen ? "0" : "10%",
            }}
          />  
        </Grid>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        minHeight: "40vh",
        maxWidth: "100vw",
      }}
    >
      {MiddleContent}
    </Grid>
  );
}

export default LandSectionProjects;
