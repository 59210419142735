import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import Button from '@mui/material/Button';
import { CardContent, Card, Grid, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';


const EmailForm = () => {

    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const isEmailValid = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (!name || !lastname || !email || !message) {
            console.error('Por favor, complete todos los campos antes de enviar el formulario.');
            return;
        }

        //Your EmailJS service ID, template ID, and public Key
        const serviceID = 'service_s5lkuhp';
        const templateID = 'template_3er8qwh';
        const publicKey = 'v1lY26P4qql-RU02M';

        const templateParams = {
            from_name: name,
            from_lastname: lastname,
            from_email: email,
            to_name: 'Web Wizard',
            message: message,
        };

        //Send the email using EmailJS
        emailjs.send(serviceID, templateID, templateParams, publicKey)
            .then((response) => {
                console.log('Email sent succesfully!', response);
                setName('');
                setLastname('');
                setEmail('');
                setMessage('');
                setOpenPopup(true); // Abrir el popup al enviar el correo
            })
            .catch((error) => {
                console.error('Error sending email:', error)
            })
    }

    const handleClosePopup = () => {
        setOpenPopup(false);
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
        setFormValid(value.length > 0 && isEmailValid(value));
    }

    return (
        <div>
            <Typography gutterBottom variant="h3" align="center">
                Formulario de contacto
            </Typography>
            <Card style={{ maxWidth: 750, margin: "0 auto", padding: "20px 5px" }}>
                <CardContent>
                    <Typography gutterBottom variant="h5">Contactenos</Typography>
                    <Typography  gutterBottom variant="body2" color="textSecondary" component="p">Llene el formulario y nuestro equipo le respondera lo mas antes posbile.</Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Nombres"
                                    placeholder="Ingrese sus nombres"
                                    variant="outlined"
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Apellidos"
                                    placeholder="Ingrese sus apellidos"
                                    variant="outlined"
                                    fullWidth
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    type="email"
                                    label="Correo Electrónico"
                                    placeholder="Ingrese su correo electrónico"
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Mensaje"
                                    rows={4}
                                    multiline
                                    placeholder="Ingrese la información que desea que le brindemos"
                                    variant="outlined"
                                    fullWidth
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} container justifyContent="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ width: '200px', height: '50px', }}
                                    disabled={!formValid}
                                >
                                    Enviar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Dialog open={openPopup} onClose={handleClosePopup}>
                <DialogTitle>¡Correo enviado!</DialogTitle>
                <DialogContent>
                    <Typography>Tu correo ha sido enviado correctamente!</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePopup} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EmailForm;